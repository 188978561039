import Section from 'components/Section'
import { useContext } from 'react'
import SectionContainer from 'components/Section/SectionContainer'
import styles from './ProductDetailsTabs.module.scss'
import { useInlineMemoizedContentGetter, useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, MediaImageStandard, Typography } from 'components/Contentful'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Grid, Box } from '@achieve/ascend'
import { Eyebrow } from 'components/Eyebrow'
import { IconListItem } from 'components/IconListItem'
import { BLOCKS } from '@contentful/rich-text-types'
import { ANCHOR_POINTS, AchieveBadge } from 'components/AchieveBadge'
import SlideOnVisible from 'components/SlideOnVisible/SlideOnVisible'
import { StickyTabs } from 'components/StickyTabs'
import {
  SLIDE_ON_VISIBLE_EASING_DEFAULT,
  SLIDE_ON_VISIBLE_DURATION_DEFAULT,
  SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT,
} from 'constants/animation'
import classNames from 'classnames'
import { AchieveLink } from 'components/AchieveLink'
import { GraphicHandler } from 'components/Graphic/GraphicHandler'
import { LayoutContext } from 'providers/LayoutProvider'
import useIsSsr from 'hooks/useIsSsr'
import AppStoreButtons from 'components/AppStoreButtons/AppStoreButtons'
import { PhoneDataContext } from 'providers/PhoneDataProvider'

function ProductDetailsTabsModule({
  title,
  eyebrow,
  grid,
  event,
  additionalConfiguration = {},
  helpTextMobile,
  primaryCta,
}) {
  const { state: { isMobileUA } = {} } = useContext(LayoutContext)
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const isMobile = isSsr ? isMobileUA : mqHookResults
  const phoneData = useContext(PhoneDataContext)
  const {
    jsonContent: { stickyTabsLabels = [], inverse = false, primaryColor, secondaryColor } = {},
  } = useInlineMemoizedContentGetter(additionalConfiguration, ['jsonContent'])
  const makeTabObject = (item, index) => {
    return {
      tabLabel:
        stickyTabsLabels[index] ?? item?.cardTitleText?.json?.content?.[0]?.content?.[0].value,
      tabItem: makeSection(item, index),
    }
  }

  const makeSection = (item, index) => {
    const {
      eyebrow,
      cardTitleText,
      cardSubtitleText,
      cardText,
      iconCardItemCollection,
      cardLink,
      secondaryCardLink,
      cardImage,
      cardImageResponsive,
      cardImageAlternative,
      cardBadgeImage,
      cardJson,
      linkAndroid,
      imageAndroid,
      linkApple,
      imageApple,
      graphicComponent,
      extraComponentsCollection,
    } = item || {}
    const badgeProps = {
      width: isMobile ? 70 : 80,
      height: isMobile ? 70 : 80,
      anchor: ANCHOR_POINTS.RIGHT_TOP,
      translateX: -90,
      translateY: 25,
      ...(isMobile ? cardJson?.achieveBadge : cardJson?.achieveBadgeLg || {}),
    }
    const highlightBox = extraComponentsCollection?.items?.filter(
      (e) => e?.fieldName == 'highlightBox'
    )?.[0]
    const callCta = extraComponentsCollection?.items?.filter((e) => e?.fieldName == 'callCta')?.[0]
    const LinkTextCallCta = callCta
      ? `${callCta?.linkText?.json?.content?.[0]?.content?.[0]?.value.replaceAll(
          '[ACX_PHONE_NUMBER]',
          phoneData.phoneNumber
        )}`
      : ''

    const appStoreButtons = (linkApple || imageApple || linkAndroid || imageAndroid) && (
      <AppStoreButtons
        appleButton={linkApple}
        appleImage={imageApple}
        googleButton={linkAndroid}
        googleImage={imageAndroid}
        navLinkSection="Product Details"
      />
    )

    const oddItem = index % 2 == 0

    return (
      <Grid
        container
        key={`product-card-${index}`}
        className={classNames(
          inverse
            ? oddItem
              ? styles['pd-item-secondary']
              : styles['pd-item']
            : oddItem
            ? styles['pd-item']
            : styles['pd-item-secondary'],
          styles[`backgroud-${cardJson?.backgroud}`] ?? '',
          (!cardImageAlternative && styles['pd-item-no-secondary-image']) ?? ''
        )}
        direction={
          isMobile
            ? 'row'
            : inverse
            ? !oddItem
              ? 'row'
              : 'row-reverse'
            : !oddItem
            ? 'row-reverse'
            : 'row'
        }
        data-testid="pd-section"
        data-variation={cardJson?.cardVariation || 'default'}
        data-inverse={inverse}
        style={{
          ...(primaryColor &&
            secondaryColor && {
              background: inverse
                ? !oddItem
                  ? primaryColor
                  : secondaryColor
                : !oddItem
                ? secondaryColor
                : primaryColor,
            }),
        }}
      >
        <Grid item xxs={12} lg={6} className={styles['pd-item-panel-left']}>
          <Box className={styles['pd-item-brand']}>
            <SlideOnVisible
              duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
              timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
              easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
            >
              <div>
                <MediaImageStandard
                  content={isMobile ? cardImageResponsive : cardImage}
                  className={styles['pd-item-image']}
                />
                {!isMobile && cardJson?.disclosure && (
                  <AchieveLink
                    href="/policies#Disclosures"
                    className={styles['disclosure-link']}
                    data-testid="product-detail-disclosure"
                    track={{
                      ...{
                        list_name: 'PRODUCT DETAIL DISCLOSURE',
                        click_url: '/policies#Disclosures',
                        click_id: 'disclosures',
                        click_text: 'disclosures',
                        click_type: 'Link Click',
                        event_action: 'link_click',
                        nav_link_section: 'Product Details',
                        track_event: 'link_click',
                      },
                      ...event,
                    }}
                  >
                    <Typography
                      className={styles[`disclosure-${cardJson?.disclosure}`]}
                      content={'Disclosures'}
                      variant={'bodyXs'}
                    />
                  </AchieveLink>
                )}
              </div>
            </SlideOnVisible>
            {cardImageAlternative && (
              <Box
                className={
                  styles[
                    !isMobile && cardJson?.disclosure
                      ? 'pd-item-card-image-disclosure'
                      : 'pd-item-card-image'
                  ]
                }
              >
                <SlideOnVisible
                  duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
                  timeout={350}
                  easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
                >
                  <div
                    className={
                      styles[isMobile && cardJson?.disclosure ? 'disclosure-mobile' : null]
                    }
                  >
                    {cardBadgeImage && <AchieveBadge content={cardBadgeImage} {...badgeProps} />}
                    {graphicComponent ? (
                      <Box data-component={graphicComponent?.component?.[0]}>
                        <GraphicHandler
                          classNameContainer={styles['pd-item-subimage-html']}
                          imageComponent={graphicComponent?.component?.[0]}
                          jsonContent={graphicComponent}
                        />
                      </Box>
                    ) : (
                      <MediaImageStandard
                        content={cardImageAlternative}
                        className={styles['pd-item-subimage']}
                        objectPosition="center-top"
                        objectFit="contain"
                      />
                    )}

                    {isMobile && cardJson?.disclosure && (
                      <AchieveLink
                        href="/policies#Disclosures"
                        className={styles['disclosure-link']}
                        data-testid="product-detail-disclosure"
                        track={{
                          ...{
                            list_name: 'PRODUCT DETAIL DISCLOSURE',
                            click_url: '/policies#Disclosures',
                            click_id: 'disclosures',
                            click_text: 'disclosures',
                            click_type: 'Link Click',
                            event_action: 'link_click',
                            nav_link_section: 'Product Details',
                            track_event: 'link_click',
                          },
                          ...event,
                        }}
                      >
                        <Typography
                          content={'Disclosures'}
                          variant={'bodyXs'}
                          style={{ marginTop: '-10px', marginLeft: '32px' }}
                        />
                      </AchieveLink>
                    )}
                  </div>
                </SlideOnVisible>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xxs={12} lg={6} className={styles['pd-item-panel-right']}>
          <SlideOnVisible
            duration={SLIDE_ON_VISIBLE_DURATION_DEFAULT}
            timeout={SLIDE_ON_VISIBLE_TIMEOUT_DEFAULT}
            easing={SLIDE_ON_VISIBLE_EASING_DEFAULT}
          >
            <Box className={styles['pd-item-info']}>
              {eyebrow && !isMobile && (
                <Eyebrow
                  className={styles['pd-item-eyebrow']}
                  content={eyebrow}
                  variant="bodySm"
                  fontWeight="bold"
                  component="h3"
                  black
                  data-testid="pd-item-eyebrow"
                />
              )}
              {cardTitleText && (
                <Typography
                  className={styles['pd-item-title']}
                  content={cardTitleText}
                  variant={isMobile ? 'bodyLg' : 'headingMd'}
                  fontWeight="medium"
                  data-testid="pd-item-title"
                />
              )}
              {cardSubtitleText && (
                <Typography
                  content={cardSubtitleText}
                  variant="bodySm"
                  className={styles['pd-item-subtitle']}
                  fontWeight="regular"
                  data-testid="pd-item-subtitle"
                />
              )}
              {cardText && (
                <Typography
                  content={cardText}
                  variant="bodySm"
                  className={styles['pd-item-vard-text']}
                  fontWeight="regular"
                />
              )}
              {iconCardItemCollection?.items?.length > 0 && (
                <ul className={styles['pd-item-list']}>
                  {iconCardItemCollection?.items.map((icon, index) => {
                    return (
                      <IconListItem
                        key={`icon-list-item-${index}`}
                        iconColor={icon?.iconColor}
                        iconName={icon?.iconName}
                        itemText={icon?.description}
                        classNameText={styles['pd-item-list-text']}
                        variant="bodySm"
                        iconSize={20}
                      />
                    )
                  })}
                </ul>
              )}

              {highlightBox && (
                <Box className={styles['highlight-box']}>
                  <MediaImageStandard
                    className={styles['highlight-box-icon']}
                    layout="fixed"
                    width={20}
                    height={30}
                    content={highlightBox?.cardImage}
                  />
                  <Typography
                    content={highlightBox?.cardText}
                    variant={isMobile ? 'bodyXs' : 'bodyXs'}
                    className={styles['highlight-box-text']}
                    fontWeight="bold"
                  />
                </Box>
              )}

              {appStoreButtons && (
                <div className={styles['app-store-btns-container']}>{appStoreButtons}</div>
              )}

              {secondaryCardLink && (
                <LinkButton
                  track={{
                    ...{
                      list_name: 'PRODUCT DETAILS',
                      click_id: secondaryCardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_text: secondaryCardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_url: secondaryCardLink?.linkHref,
                      nav_link_section: 'Product Details',
                      track_event: 'internal_campaign_click',
                      event_action: 'button_click',
                      event_type: 'button_click',
                    },
                    ...secondaryCardLink?.fields?.event,
                  }}
                  content={secondaryCardLink}
                  typographicOptions={{
                    variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  className={styles['button']}
                  variant="contained"
                  fullWidth={isMobile}
                  data-testid="pd-item-secondary-cta"
                />
              )}

              {cardLink && (
                <LinkButton
                  track={{
                    ...{
                      list_name: 'PRODUCT DETAILS LINK',
                      click_id: cardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_text: cardLink?.linkText?.json?.content[0]?.content[0]?.value,
                      click_type: 'Link Click',
                      click_url: cardLink?.linkHref,
                      nav_link_section: 'Product Details',
                      track_event: 'link_click',
                      event_action: 'link_click',
                      event_type: 'link_click',
                    },
                    ...cardLink?.fields?.event,
                  }}
                  content={cardLink}
                  typographicOptions={{
                    variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
                    fontWeight: 'bold',
                  }}
                  color="primary"
                  className={styles[('card-link', 'button')]}
                  variant="text"
                  fullWidth={isMobile}
                  data-testid="pd-item-primary-cta"
                />
              )}

              {callCta && phoneData.phoneNumber && (
                <AchieveLink
                  href={`tel:${phoneData.phoneNumber}`}
                  noLink
                  noPreserveParams
                  track={{
                    list_name: 'Product Details Phone',
                    click_id: LinkTextCallCta,
                    click_type: 'Phone Click',
                    click_text: `Achieve Web | ${LinkTextCallCta}`,
                    nav_link_section: 'Product Details',
                    track_event: 'link_click',
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    variant={isMobile ? 'bodySm' : 'bodyLg'}
                    content={LinkTextCallCta}
                  />
                </AchieveLink>
              )}

              {cardJson?.disclaimer && (
                <Typography content={cardJson?.disclaimer} variant={'bodyXs'} />
              )}
            </Box>
          </SlideOnVisible>
        </Grid>
      </Grid>
    )
  }

  return (
    <Section
      className={classNames(
        styles['section'],
        styles[`backgroud-${grid?.gridItemsCollection?.items?.[0]?.cardJson?.backgroud}`] ?? ''
      )}
      contain={false}
      style={{ ...(primaryColor && { backgroundColor: primaryColor }) }}
    >
      <Typography
        className={styles['pd-eye-brow']}
        textAlign="center"
        variant="bodySm"
        fontWeight="bold"
        content={eyebrow?.textContent}
      />
      <Typography
        className={styles['pd-title']}
        fontWeight="medium"
        textAlign="center"
        variant={isMobile ? 'headingMd' : 'displayLg'}
        content={title?.textContent}
        data-testid="pd-title"
      />
      {grid?.gridItemsCollection?.items && (
        <Box>
          {!isMobile ? (
            <>{grid?.gridItemsCollection?.items?.map((item, index) => makeSection(item, index))}</>
          ) : (
            <StickyTabs
              className={styles['sticky-tabs']}
              isMobile={true}
              stickyTabsContent={grid?.gridItemsCollection?.items?.map((item, index) =>
                makeTabObject(item, index)
              )}
              event={event}
            />
          )}
        </Box>
      )}
      {isMobile && primaryCta && (
        <SectionContainer>
          <LinkButton
            track={{
              ...{
                list_name: 'PRODUCT DETAILS',
                click_id: primaryCta?.linkText?.json?.content[0]?.content[0]?.value,
                click_text: primaryCta?.linkText?.json?.content[0]?.content[0]?.value,
                click_url: primaryCta?.linkHref,
                nav_link_section: 'Product Details',
                track_event: 'internal_campaign_click',
                event_action: 'button_click',
                event_type: 'button_click',
              },
              ...primaryCta.event,
            }}
            content={primaryCta}
            typographicOptions={{
              variantOverride: { [BLOCKS.PARAGRAPH]: 'bodyLg' },
              fontWeight: 'bold',
            }}
            color="primary"
            className={styles['button']}
            variant="contained"
            fullWidth={isMobile}
            data-testid="pd-item-secondary-cta"
          />
        </SectionContainer>
      )}
      {isMobile && helpTextMobile && (
        <SectionContainer>
          <Typography
            className={styles['pd-help-text']}
            fontWeight="regular"
            variant="bodyXs"
            content={helpTextMobile?.textContent?.json}
            data-testid="pd-help-text"
          />
        </SectionContainer>
      )}
    </Section>
  )
}

function ProductDetailsTabs({ content }) {
  const { title, eyebrow, grid, helpTextMobile, primaryCta, event } = useMemoizedContentGetter(
    content,
    ['title', 'eyebrow', 'grid', 'helpTextMobile', 'primaryCta', 'event']
  )

  return (
    <ProductDetailsTabsModule
      title={title}
      eyebrow={eyebrow}
      grid={grid}
      event={event}
      additionalConfiguration={content?.fields?.additionalConfiguration}
      helpTextMobile={helpTextMobile}
      primaryCta={primaryCta}
    />
  )
}
export { ProductDetailsTabs, ProductDetailsTabsModule }
export default ProductDetailsTabs
